import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Cadastro from "./pages/Cadastro";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Painel from "./pages/Painel";

export default function Routes() {
	return (
    <BrowserRouter basename="/">
		<Switch>
			<Route exact path="/">
				<Home />
			</Route>
			<Route path="/painel">
				<Painel />
			</Route>
			<Route path="/login">
				<Login />
			</Route>
			<Route path="/inicio">
				<Home />
			</Route>
			<Route path="/atendimento">
				<Cadastro />
			</Route>
      <Route path="/itinerante">
				<Cadastro />
			</Route>
			<Route path="/treinamento">
				<Cadastro />
			</Route>
			<Route path="/cadastro/:id">
				<Cadastro />
			</Route>
			 {/* Rota "catch-all" que redireciona para a página inicial */}
			 <Route path="*">
          <Redirect to="/" />
        </Route>
		</Switch>
    </BrowserRouter>
	);
}
