import React from 'react';
import { ChatContainer, Container, Rabinho, Avatar } from './styles';
import ChatTail from '../../assets/chat.svg';
import Ana from '../../assets/avatar.jpg';

export default function ChatMessage({ children }) {
  return (
    <ChatContainer>
      <Avatar src={Ana} />
      <Container>
        <Rabinho src={ChatTail} width='20' alt='' />
        {children}
      </Container>
    </ChatContainer>
  );
}
