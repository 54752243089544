import React from "react";
import DrawerMenu from "../../components/DrawerMenu";

export default function Painel() {
  return (
    <DrawerMenu>
      <h2>teste</h2>
    </DrawerMenu>
  );
}
