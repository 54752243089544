import React from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Atendimento,
  TitleContainer,
  Title,
  Subtitle,
  LeftImg,
  Button,
  ButtonsContainer,
} from "./styles";
import Menu from "./Menu";
import AtendimentoImg from "../../assets/atendimento.png";
import Left from "../../assets/left.png";

export default function Login() {
  let history = useHistory();
  return (
    <>
      <Menu />
      <Container>
        <LeftImg src={Left} alt="" />
        <TitleContainer>
          <Title>PORTAL DE ATENDIMENTO</Title>
          <Subtitle>
            Agora a Defensoria Pública do Acre pode realizar seus atendimentos
            online!
          </Subtitle>
          <ButtonsContainer>
            {/* <Button onClick={() => history.push("/login")}>Acessar</Button> */}
            <Button onClick={() => history.push("/atendimento")}>
             Quero ser atendido 
            </Button>
            {/* <Button onClick={() => history.push("/itinerante")}>
            Atendimento Exclusivo Itinerante
            </Button> */}
          </ButtonsContainer>
        </TitleContainer>
        <Atendimento src={AtendimentoImg} />
      </Container>
    </>
  );
}
