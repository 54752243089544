import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//import "./reset.css";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { StateMachineProvider, createStore } from "little-state-machine";
import 'bootstrap/dist/css/bootstrap.min.css';
createStore({
	data: { step: 0, nacionalidade: "Brasileiro" }
});

ReactDOM.render(
	<React.StrictMode>
		<StateMachineProvider>
			<Router>
				<App />
			</Router>
		</StateMachineProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
