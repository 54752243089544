 
 // Codio do arrray das varas 
//  "0" => lista todas as varas e atendimento iniciais 
//  "1" => Lista somente as varas civeis 
//  "2" => Lista somente as vara criminais 
//  "3" => lista departamentos que atuam como vara e atendimento inicial civel ex: vara unicas do interior 
//  "4" => lista departamentos que atuam como vara e atendimento inicial crimial ex: vara unicas do interior
 
 
 let Depart =[
    {_id: "ZNcFBr7wx6Sz4EnzH",  name: "01 - Atendimento Cível", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,2]},
    {_id: "mTMeTuRJkvgg7nHLg",  name: "02 - Atendimento Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal"],vara: [0,2]},
    {_id: "mTMeTuRJkvgg7nHLg",  name: "02 - Atendimento Criminal", depart_estadual: false, capital: ["Brasiléia","Cruzeiro_do_Sul","Porto_Acre","Senador_Guiomard"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"],vara: [0,1,2]},
    {_id: "7wEWzWKugWB55LhDi",  name: "03 - Vara de Execuções Penais", depart_estadual: true, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [1,2]},
    {_id: "nCjw4Y2q2PafvJwFK",  name: "04 - Central de Alimentos ", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","Pensão_alimentícia_encaminhar","Pensão_alimentícia_atrasada"], vara: [0,1,2]},
    {_id: "YpwJDnghsgptAwoFN",  name: "05 - Núcleo da Saúde", depart_estadual: false, description: "Núcleo da Saúde", capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [0,1,2]},
    // {_id: "Rf46fP6zygBRHxZb3",  name: "06 - Acordo Zap", depart_estadual: false, description: "Acordo Zap", capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [0,1,2]},
    {_id: "GAca2nxZmgzEL9TwS",  name: "07 - Núcleo do Consumidor", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [0,1,2]},
    // {_id: "uSCiS7kk6ZKvniFbr",  name: "08 - Vara de Órfãos e Sucessões e Registros Públicos", capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "7JYiHRqWMvkjGnHxM",  name:"09 - 1ª e 2ª Vara da Fazenda Pública e Vara de Execução Fiscal", capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "iX7zmFPPnJhrzFeaG",  name: "10 - 5ª Vara Cível ", depart_estadual: false, description: "5ª Vara Cível ", capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "nMG5LtwRQnz5mZiDR",  name: "11 - 1ª e 2ª Varas Cíveis", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "5iug7m4d9h8LZvjud",  name: "12 - 3ª e 4ª Varas Cíveis", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "iJCBv3C4sTAm9EnLY",  name: "13 - 1º Juizado Especial Cível", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "xmvBL2bAi4nxvTq7P",  name: "14 - 2º Juizado Especial Cível", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "cKGeuXbAPwbJuRaCc",  name: "15 - 3º Juizado Especial Cível",  depart_estadual: false,capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]},
    {_id: "zd3Z597HEmeSH7Qqh",  name: "16 - Juizado Especial Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    // {_id: "GMfZNxoj7hRnCFJRz",  name: "17 - 2º Juizado Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "xxEaB5bPi46cjP4QR",  name: "18 - 1ª Vara Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "EEoKmcBt3K5cfndrK",  name: "19 - 2ª Vara Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "x6pMQnYRMtbFRWAP3",  name: "20 - 3ª Vara Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "CtRDHydFpSPSnaeCE",  name: "21 - 4ª Vara Criminal", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "wTJZ3NfLjDc7zaZZc",  name: "22 - Vara de Delitos de Roubo e Extorsão", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]}, 
    {_id: "xhjTmEqAQ4kCG5gHQ",  name: "23 - Vara de Organizações Criminosas", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    // {_id: "2ggj2e9axCrX2hkDR",  name: "24 - 2ª Vara do Tribunal do Júri", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "5PfCaWtnr8WgoNy6A",  name: "25 - Vara de Proteção à Mulher - Vítima", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal","Violência_Domestica_encaminhar"], vara: [1,2]},
    {_id: "ebcYN4iHJmcrKAnKv",  name: "26 - Vara Proteção à Mulher - Réu/Ré", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal","Violência_Domestica_encaminhar"], vara: [1,2]},
    {_id: "i2omjW3sXtQw3fqho",  name: "84 - 2ª Vara de Proteção a Mulher", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal","Violência_Domestica_encaminhar"], vara: [1,2]},
    // {_id: "oQ2hsC5tuAsvn2nLZ",  name: "27 - 1ª Vara da Infância e Juventude", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil","consulta_processo_criminal"], vara: [1,2]},
    // {_id: "9Bi9p9RWaDZdFjt5q",  name: "28 - 2ª Vara da Infância e da Juventude", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil","consulta_processo_criminal"], vara: [1,2]},
    {_id: "FXJkN5E7adQKF825G",  name: "29 - Câmara Criminal - Processos Pares", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    {_id: "miuzffWBxByXzFoPp",  name: "30 - Câmara Criminal - Processos Ímpares", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_criminal"], vara: [1,2]},
    // {_id: "oBGoTuth5bBH68vCi",  name: "41 - Ouvidoria Geral", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [0,1,2]},
    // {_id: "8Lq54BAMAsnuZeJuB",  name: "42 - 1ª Vara de Família", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil","vara_de_familia"], vara: [1,2]},
    // {_id: "7bZnXX4hafvZzp8b3",  name: "43 - 2ª Vara de Família", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil","vara_de_familia"], vara: [1,2]},
    // {_id: "JL2iZYrAj63u7sgMh",  name: "44 - 3ª Vara de Família", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil","vara_de_familia"], vara: [1,2]},
    {_id: "XZqNdghBEmDanW7fA",  name: "45 - Coordenação Cível", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [1,2]},
    {_id: "FN4EvMH7smX9Mudsi",  name: "46 - Subnúcleo de Direitos Humanos 1", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [1,2]},
    {_id: "THz7AKjiFxd3xEMmx",  name: "47 - Subnúcleo de Direitos Humanos 2", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [1,2]},
    //{_id: "ibNNAiNFYcfodHoru", enabled: ["Todos","Rio_Branco"], name: "48 - Unidade Cruzeiro do Sul - Vara Criminal",}
    {_id: "L94zru3iy3YQwyjbn",  name: "49 - Unidade Família", depart_estadual: false, description: "Unidade Família", capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [1,2]},
    //{_id: "YSuF7LjL7o3AgDwBQ", enabled: ["Todos","Rio_Branco"], name: "50 - Unidade Sobral", description: "Unidade Sobral",}
    // {_id: "J7M2KRxK2oxRzsGow",  name: "52 - Atendimento Itinerante-Cível", capital: ["Todos","Rio_Branco"], motivo:["Todos"]},
    // {_id: "JjckDZvCXLssN8QyE",  name: "53 - Atendimento Itinerante-Criminal", capital: ["Todos","Rio_Branco"], motivo:["Todos"]},
    {_id: "YPx3afiRExQxPD3WA",  name: "54 - Serviço Social-DNA-Acordo", depart_estadual: false, description: "", capital: ["Todos","Rio_Branco"], motivo:["Todos","DNA"], vara: [0,2]},
    {_id: "RBtikzvEE4QbLzddP",  name: "55 - Psicologia", depart_estadual: false, description: "", capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [0,1,2]},
    // {_id: "LmPR8xdNmk8sgLyYZ",  name: "59 - Execução de Honorários", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [1,2]},
   //e {_id: "a243K5kQfv3hFXcG8", enabled: ["Todos","Rio_Branco"], name: "62 - Unidade de Brasiléia - Vara Criminal",}
    {_id: "eKDXvnP5RQW55v9kK",  name: "71 - Atendimento Colidências", capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [1,2]},
    {_id: "95w8b2uCJbbDj9qva",  name: "72 - Juizado Especial da Fazenda Pública", depart_estadual: false, capital: ["Todos","Rio_Branco"], motivo:["Todos","consulta_processo_civil"], vara: [1,2]}, 
    {_id: "zMNZuSkzwT494tbvq",  name: "99 - Teste",  depart_estadual: false,capital: ["Todos","Rio_Branco"], motivo:["Todos"], vara: [0,1,2]},


    // unidades interior civel 

    // {_id: "BRTkkRwhA2F7SL3tc",  name: "31 - Unidade Acrelândia - Vara Cível", depart_estadual: false, capital: ["Todos","Acrelândia"], motivo:["Todos","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","primeiro_atendimento_civil","consulta_processo_civil","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    // {_id: "aT2FSKQix5FHar25u",  name: "32 - Unidade Brasileia - Vara Cível", depart_estadual: false, capital: ["Todos","Brasiléia"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","Violência_Domestica_encaminhar","DNA","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    // {_id: "H54vQJNLy6Ro64RRY",  name: "33 - Unidade Bujari - Vara Cível", depart_estadual: false, capital: ["Todos","Bujari"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    
     {_id: "T6QLF6CyDAREG8ku9",  name: "34 - Unidade Cruzeiro do Sul - Vara Cível ", depart_estadual: false, capital: ["Todos","Cruzeiro_do_Sul"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","Violência_Domestica_encaminhar","DNA","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]}, 
   
    // {_id: "vRFZHSpp2NJ79AFzD",  name: "35 - Unidade Feijó - Vara Cível", depart_estadual: false, capital: ["Todos","Feijó"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    {_id: "E3qyiDmjTvEXnLHxp",  name: "36 - Unidade Mâncio Lima - Vara Cível", depart_estadual: false, capital: ["Todos","Mâncio_Lima"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    // {_id: "HQPSHLGjqQQqNAajd",  name: "37 - Unidade Plácido de Castro", depart_estadual: false, capital: ["Todos","Plácido_de_Castro"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    // {_id: "tyxK8mcEk55rK6uab",  name: "38 - Unidade Sena Madureira - Vara Cível", depart_estadual: false, capital: ["Todos","Sena_Madureira"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    {_id: "2RKWTBQbZwQnubG6R",  name: "39 - Unidades Senador Guiomard - Vara Cível", depart_estadual: false, capital: ["Todos","Senador_Guiomard"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    // {_id: "duFNs6TwJfFqi8bfA",  name: "40 - Unidade Tarauacá - Vara Cível", depart_estadual: false, capital: ["Todos","Tarauacá"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    {_id: "4qdwLDvaT2TjHcNZF",  name: "51 - Unidade de Porto Acre", depart_estadual: false, capital: ["Todos","Porto_Acre"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    {_id: "mAH54TMtuSuPXsNto",  name: "58 - Rodrigues Alves - Vara Cível", depart_estadual: false, capital: ["Todos","Rodrigues_Alves"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    // {_id: "xMXBpLaCrxvGLRuPy",  name: "61 - Unidade de Epitaciolândia - Vara Cível", depart_estadual: false, capital: ["Todos","Epitaciolândia"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]}, 
    {_id: "WxJLdSYsaELSWPZTq",  name: "67 - Unidade de Capixaba - Vara Cível", depart_estadual: false, capital: ["Todos","Capixaba"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},
    
    {_id: "PXeLr8Mixzwo2aGyL",  name: "75 - Unidade de Manoel Urbano - Cível", depart_estadual: false, capital: ["Todos","Manoel_Urbano"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","Falecimento_de_familiar_inventário_encaminhar","Pensão_alimentícia_encaminhar","Casamento_união_estável_divórcio_ou_separação_encaminhar","DNA","Violência_Domestica_encaminhar","Pensão_alimentícia_atrasada","vara_de_familia","Pagamendo_de_Pensão","Intimação_Pensão","Guarda_Curatela","Investigação_de_Paternidade"], vara: [0,1,2]},

        // atua civel e criminal
    {_id: "8bK5aPmWJco3mtZaN",  name: "57 - Cruzeiro do Sul - Vara da Infância e Juventude", depart_estadual: false, capital: ["Todos","Cruzeiro_do_Sul"], motivo:["Todos","primeiro_atendimento_civil","consulta_processo_civil","primeiro_atendimento_criminal","consulta_processo_criminal"], vara: [0,1,2]},
        // atua civel e criminal

    // interior vara criminal
    {_id: "yBkXoAdkZTxPBrhcy",  name: "76 - Unidade de Manoel Urbano - Criminal", depart_estadual: false, capital: ["Todos","Manoel_Urbano"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    {_id: "C6ArHsuzNqEyNEuYx",  name: "77- Unidade de Assis Brasil - Criminal", depart_estadual: false, capital: ["Todos","Assis_Brasil"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},

    {_id: "dsEvNDYTGYzroHE3h",  name: "63 - Unidade Tarauacá - Criminal", depart_estadual: false, capital: ["Todos","Tarauacá"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    // {_id: "gAy76Cnwt39j6EZ9a",  name: "64 - Unidade Sena Madureira -Criminal", depart_estadual: false, capital: ["Todos","Sena_Madureira"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    // {_id: "KbozEuumt7JFhmQa5",  name: "64 - Unidade de Feijó - Vara Criminal", depart_estadual: false, capital: ["Todos","Feijó"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    // {_id: "oB9dSysFtWb9cQBN3",  name: "65 - Unidade de Plácido de Castro -Vara Criminal", capital: ["Todos","Plácido_de_Castro"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    // {_id: "FomFdPJa8PRLFyWwt",  name: "66 - Unidade de Bujari - Vara Criminal",  depart_estadual: false,capital: ["Todos","Bujari"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    {_id: "kDgPTiFrettMK4Y5m",  name: "68 - Unidade de Capixaba - Vara Criminal", depart_estadual: false, capital: ["Todos","Capixaba"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    {_id: "7TRRbMKPx6SXfFCHm",  name: "69 - Unidade de Acrelândia - Vara Criminal", depart_estadual: false, capital: ["Todos","Acrelândia"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},
    {_id: "pa92oMB3YPSazKr8z",  name: "70 - Unidade Mâncio Lima - Vara Criminal", depart_estadual: false, capital: ["Todos","Mâncio_Lima"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [1,2]},
    {_id: "9HjGxz4h8CSooaJQX",  name: "56 - Rodrigues Alves - Vara Criminal", depart_estadual: false, capital: ["Todos","Rodrigues_Alves"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]}, 
    {_id: "TCwJBS7vMAEGe9FJA",  name: "60 - Unidade de Epitaciolândia - Vara Criminal", depart_estadual: false, capital: ["Todos","Epitaciolândia"], motivo:["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado"], vara: [0,1,2]},

    // novos cruzeiro do sul 09/01/2023

    {_id: "RP85bFKgcwRdvK84j",  name: "78- 1ª vara criminal - Cruzeiro do Sul", depart_estadual: false, capital: ["Cruzeiro_do_Sul"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado","Violência_Domestica_encaminhar"],vara: [0,1,2]},
    {_id: "bdAjHvRue3cN2wcxa",  name: "79 - 2 vara criminal - Cruzeiro do sul", depart_estadual: false, capital: ["Cruzeiro_do_Sul"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado","Violência_Domestica_encaminhar"],vara: [0,1,2]},
    {_id: "HcaD7PQTGZHLNSw44",  name: "80- Juizado especial criminal - cruzeiro do sull", depart_estadual: false, capital: ["Cruzeiro_do_Sul"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado","Violência_Domestica_encaminhar"],vara: [0,1,2]},
    {_id: "Mia5oMceQhDS3MaSg",  name: "81- Juizado especial cível da fazenda pública - cruzeiro do sull", depart_estadual: false, capital: ["Cruzeiro_do_Sul"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado","Violência_Domestica_encaminhar"],vara: [0,1,2]},
    {_id: "wQpqMkHNsW6XBNYJX",  name: "82- Juizado especial cível - cruzeiro do sul", depart_estadual: false, capital: ["Cruzeiro_do_Sul"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado","Violência_Domestica_encaminhar"],vara: [0,1,2]},
    {_id: "QPYLBnuipNnLKndmN",  name: "83-Vara de proteção a mulher - Cruzeiro do sul", depart_estadual: false, capital: ["Cruzeiro_do_Sul"], motivo: ["Todos","primeiro_atendimento_criminal","consulta_processo_criminal","RAP","Semiaberto_Aberto","Monitoramento","Remição_de_pena","Transferência_de_execução","Regime_Fechado","Violência_Domestica_encaminhar"],vara: [0,1,2]},


]
export default Depart;