import React from "react";
import { MenuContainer, MenuItem, MenuLeft, MenuRight, Logo } from "./styles";
import BlackLogo from "../../assets/black-logo.png";

export default function Menu() {
  return (
    <MenuContainer>
      <MenuLeft>
        <Logo src={BlackLogo} />
      </MenuLeft>
      <MenuRight>
        <MenuItem>Como funciona?</MenuItem>
        <MenuItem>Quem pode ser atendido ?</MenuItem>
      </MenuRight>
    </MenuContainer>
  );
}
