 
 
 
 let lista_motivos =[
    {_id: "1", value: "primeiro_atendimento_civil",  name: "Primeiro atendimento Cível"},
   // {_id: "2", value: "primeiro_atendimento_criminal",  name: "Primeiro atendimento Criminal"},
    {_id: "3", value: "consulta_processo_civil",  name: "Consulta de Processo Cível"},
    {_id: "4", value: "consulta_processo_criminal",  name: "Consulta de Processo Criminal"},
    {_id: "5", value: "DNA",  name: "Exame de DNA"},
    {_id: "6", value: "Hipossuficiência",  name: "Declaração de Hipossuficiência"},
    {_id: "7", value: "Documentos",  name: "Documentos Necessário para entrar com uma Ação"},
    {_id: "8", value: "RAP",  name: "Cálculo de pena / RAP"},
    {_id: "9", value: "Semiaberto_Aberto",  name: "Semiaberto / Aberto"},
    {_id: "10", value: "Monitoramento",  name: "Tornozeleira eletrônica / Monitoramento"},
    {_id: "11", value: "Remição_de_pena",  name: "Remição de pena/Fichas"},
    {_id: "12", value: "Transferência_de_execução",  name: "Transferência de execução "},
    {_id: "13", value: "Falecimento_de_familiar_inventário_encaminhar",  name: "Falecimento de familiar / inventário"},
    {_id: "14", value: "Pensão_alimentícia_encaminhar",  name: "Pensão alimentícia"},
    {_id: "15", value: "Casamento_união_estável_divórcio_ou_separação_encaminhar",  name: "Divórcio, união estável "},
    {_id: "16", value: "Violência_Domestica_encaminhar",  name: "Violência Domestica"},
    {_id: "17", value: "Emergencial",  name: "Auxílio Emergencial"},
    {_id: "18", value: "Diversos",  name: "Questões Trabalhistas, Processos Federais, Processos Eleitorais "},
    {_id: "19", value: "Todos",  name: "Outros"},
    {_id: "20", value: "Regime_Fechado",  name: "Regime Fechado"},
    {_id: "21", value: "Pensão_alimentícia_atrasada",  name: "Pensão alimentícia - atrasada"},
    {_id: "22", value: "vara_de_familia",  name: "Vara de família"},
    {_id: "22", value: "Pagamendo_de_Pensão",  name: "Pagamendo de Pensão"},
    {_id: "22", value: "Intimação_Pensão",  name: "Intimação/Pensão"},
    {_id: "22", value: "Guarda_Curatela",  name: "Guarda,Curatela"},
    {_id: "22", value: "Investigação_de_Paternidade",  name: "Investigação de Paternidade"},


    

]
export default lista_motivos;